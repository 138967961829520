import { sendUtagEvent } from '../../../../core/analytics/utag_events'
import on from '../../../../utils/events_delegation'

export function initializeUtagEvents() {
  //Link Puntos Provisionales
  on({
    eventName: 'click',
    selector: '.utag-modal-points-extract',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'manage_profile',
          event_cat: 'manage my profile',
          event_act: 'collect points',
          event_lbl: 'view points statement',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })
  //Link Extracto de Puntos
  on({
    eventName: 'click',
    selector: '.utag-modal-provisional-points',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'manage_profile',
          event_cat: 'manage my profile',
          event_act: 'collect points',
          event_lbl: 'view provisional points',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })
  // Show here
  on({
    eventName: 'click',
    selector: '.utag-view-staragents-code',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'manage_profile',
          event_cat: 'manage my profile',
          event_act: 'collect points',
          event_lbl: 'view star agents code info',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })
  //Next Button
  on({
    eventName: 'click',
    selector: '.utag-view-collect-points-form',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'manage_profile',
          event_cat: 'manage my profile',
          event_act: 'collect points',
          event_lbl: 'view collect points form',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })

  //Step Two-Form
  // Botón Guardar - Try Form
  on({
    eventName: 'click',
    selector: '.utag-try-form',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'try_form',
          event_cat: 'form',
          event_act: 'try_form-collect points',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })
  // Back Button
  on({
    eventName: 'click',
    selector: '.utag-prev-button',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'start_form',
          event_cat: 'form',
          event_act: 'start_form-collect points',
          event_lbl: 'bac',
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    },
  })
}
