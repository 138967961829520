<template>
  <div class="main-submenu">
    <nav class="main-submenu-nav">
      <ul class="bottom-main-nav submenu-vue">
        <template v-for="(item, index) in menuItems" :key="index">
          <!-- We check that My IberostarPro does not come up as it is in the user menu.  -->
          <MainNavItem
            v-if="showItem(item)"
            :item="item"
            :utagEventInfo="item.utag_event_info"
            :currentItem="currentItem"
            @selected="setSelectedItem"
            @unSelected="setDeselectedItem"
          ></MainNavItem>
        </template>
        <!-- Only visible in IbePRO and STA -->
        <MainNavUser
          v-if="isNavUserVisible"
          :item="{
            badge: 'myiberostar',
          }"
          :dataUser="dataUser"
          :config="headerConfig"
          :currentItem="currentItem"
          @selected="setSelectedItem"
          @unSelected="setDeselectedItem"
        >
        </MainNavUser>
      </ul>
    </nav>
    <div class="main-submenu-panels vue-submenu">
      <template v-for="(item, index) in mainNavPanels" :key="index">
        <MainNavPanel
          :item="item"
          :currentItem="currentItem"
          @onPanel="onPanel"
          @outPanel="outPanel">
        </MainNavPanel>
      </template>
      <MainNavPanelHotelsDestinations
        v-if="menuItems.find(item => item.badge === 'continents')"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="hotels_and_destinations"></slot>
      </MainNavPanelHotelsDestinations>

      <MainNavPanelOffers
        v-if="menuItems.find(item => item.badge === 'offers')"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="offers"></slot>
      </MainNavPanelOffers>

      <MainNavPanelLastSearchs
        v-if="userLastSearches && menuItems.find(item => item.badge === 'searches')"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="last_searchs"></slot>
      </MainNavPanelLastSearchs>

      <MainNavPanelBooking
        v-if="menuItems.find(item => item.badge === 'bookings')"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="bookings"></slot>
      </MainNavPanelBooking>

      <MainNavPanelHorizons
        v-if="!(is_ibp || is_sta)"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="horizons"></slot>
      </MainNavPanelHorizons>

      <MainNavPanelIberostarPro
        v-if="is_ibp"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="iberostar_pro"></slot>
      </MainNavPanelIberostarPro>

      <MainNavPanelIberostarSta
        v-if="is_sta"
        @onPanel="onPanel"
        @outPanel="outPanel"
      >
        <slot name="iberostar_sta"></slot>
      </MainNavPanelIberostarSta>
    </div>
  </div>
</template>

<script>
import MainNavItem from '../MainNavItem/MainNavItem';
import MainNavPanel from '../MainNavPanel/MainNavPanel.vue';
import MainNavPanelBooking from '../MainNavPanelBooking/MainNavPanelBooking';
import MainNavPanelHorizons from '../MainNavPanelHorizons/MainNavPanelHorizons';
import MainNavPanelHotelsDestinations from '../MainNavPanelHotelsDestinations/MainNavPanelHotelsDestinations';
import MainNavPanelIberostarPro from '../MainNavPanelIberostarPro/MainNavPanelIberostarPro';
import MainNavPanelIberostarSta from '../MainNavPanelIberostarSta/MainNavPanelIberostarSta';
import MainNavPanelLastSearchs from '../MainNavPanelLastSearchs/MainNavPanelLastSearchs';
import MainNavPanelOffers from '../MainNavPanelOffers/MainNavPanelOffers';
import MainNavUser from '../MainNavUser/MainNavUser';

const STA_MANAGER_CLASS = 'js-sta-manager';

const componentName = 'MainNav';
export default {
  name: componentName,
  components: {
    MainNavItem,
    MainNavUser,
    MainNavPanel,
    MainNavPanelHotelsDestinations,
    MainNavPanelOffers,
    MainNavPanelLastSearchs,
    MainNavPanelBooking,
    MainNavPanelHorizons,
    MainNavPanelIberostarPro,
    MainNavPanelIberostarSta,

  },
  props: {
    menuItems: Array,
    headerConfig: Object,
    dataUser:Object,
    is_ibp: {
      type: Boolean,
      default: false
    },
    is_sta: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isUserLogged: false,
      userLastSearches: null,
      currentItem: null,
      staManager: false,
    }
  },
  computed: {
    isNavUserVisible() {
      this.isUserLogged = IB.browsing_sessions.isUserLogged()
      if (document.documentElement.dataset.inMicrosite === 'star_agents') {
        return true
      }

      if (document.documentElement.dataset.inMicrosite !== 'iberostar_pro') {
        return false
      }
      // Don't show User Navigation in ibercuba and starAgetn
      if (document.documentElement.dataset.inMicrosite === 'ibercuba') {
        return false
      }

      return true
    },

    mainNavPanels() {
      return this.menuItems.filter((item) => item.kind === 'show_sub_menu' && !item.badge)
    }
  },
  mounted() {
    IB.browsing_sessions.readRedis(this.init);
    this.userLastSearches = localStorage.getItem('last_searches') && JSON.parse(localStorage.getItem('last_searches'));
  },
  methods: {
    init() {
      if (IB.browsing_sessions !== null && IB.browsing_sessions.getData()) {
        this.user = IB.browsing_sessions.getData().redis_values;
        this.staManager = this.user.sta_manager === 'true'? true: false
      }
    },
    setSelectedItem(item) {
      this.currentItem = item;
      // TODO: Change to emit instead of calling parent function. Not working fow unknown reason.
      this.$parent.selectedItem(item);
    },
    setDeselectedItem(item) {
      this.currentItem = '';
      // TODO: Change to emit instead of calling parent function. Not working fow unknown reason.
      this.$parent.unSelectedItem(item);
    },
    onPanel(item){
      this.currentItem = item;
      // TODO: Change to emit instead of calling parent function. Not working fow unknown reason.
      this.$parent.isPanelOpen(item)
    },
    outPanel(item){
      this.currentItem = '';
      // TODO: Change to emit instead of calling parent function. Not working fow unknown reason.
      this.$parent.isPanelClose(item)
    },
    showItem(item) {
      return !(item.kind == 'show_sub_menu' && item.name != undefined && item.name.includes('IberostarPro')) && this.showSTAManagerItem(item)
    },
    showSTAManagerItem(item) {
      if (item.css_class === STA_MANAGER_CLASS) {
        return this.staManager
      }
      return true
    }
  },
}
</script>
