import { getPageVariation } from '../../core/analytics/shared.analytics'
import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getCleanedString } from '../../utils/utilities'

export function initializeUtagEvents() {
  const utagGalleryAnchors = document.querySelectorAll('.utag-anchor-gallery')

  utagGalleryAnchors.forEach(anchor => {
    anchor.addEventListener('click', e => {
      const event_lbl = e.target.getAttribute('data-utag-anchor-item-name')
      const reb_menu = document.querySelector('.reb-header')
      sendUtagEvent({
        data: {
          event_name: 'menu_hotel_gallery',
          event_cat: 'hotel information',
          event_act: 'menu gallery',
          event_lbl: getCleanedString(event_lbl),
          event_purpose: 'inspiration',
          event_structure: reb_menu !== null ? 'header' : 'popup',
        },
      })
    })
  })

  document.querySelectorAll('.utag-menu-hotel').forEach(link => {
    link.addEventListener('click', function (event) {
      const target = event.target
      const reb_menu = document.querySelector('.reb-header')
      sendUtagEvent({
        data: {
          event_name: 'menu_hotel',
          event_cat: 'menu-hotel detail',
          event_act: target.parentNode.dataset.utagMenuItemName,
          event_lbl: '',
          event_purpose: 'inspiration',
          event_structure: reb_menu !== null ? 'header' : 'body',
          page_variation: getPageVariation(),
        },
      })
    })
  })
}
