import './head_video'

import { currentDevice } from '../../core/utils/currentDevice'
import Modal from '../../shared/modal/modal'
import { initModal } from '../../shared/offer_modal/offer_modal'
import on from '../../utils/events_delegation'
import getRealMarketContent, { dispatchRealMarketContentUpdatedEvent } from '../../utils/get_real_market_content'
import { FILTER_OFFER_EVENT } from '../hotels_info_with_filtering_packed/hotels_info_with_filtering.constants'

import { initializeUtagEvents } from './header_customized.analytics'

const BLOCK_SELECTOR = '.js-header-customized'
const SHOW_CONDITIONS_BUTTON_SELECTOR = '.js-offer-cta-show-conditions'

function deactivateAllFilterBlocks() {
  document.querySelectorAll('.js-filter').forEach(filterBlock => {
    filterBlock.classList.remove('active')
    filterBlock.querySelector('.js-filter-block').style.display = 'none'

    const filterBlockTitle = filterBlock.querySelector('.js-filter-title')
    filterBlockTitle.classList.remove('active')
    filterBlockTitle.setAttribute('aria-expanded', 'false')
  })
}

function initShowHotelButtonClickLogic() {
  function handlerShowHotelButtonClickLogic(event) {
    Modal.close()
    const offerId = event.target.dataset.offerId
    const customEvent = new CustomEvent(FILTER_OFFER_EVENT, { detail: { offerId } })
    document.dispatchEvent(customEvent)
  }

  on({ eventName: 'click', selector: '.js-offer-cta-show-hotels', handler: handlerShowHotelButtonClickLogic })
}

function initializeShowConditionsButton() {
  const showConditionsButton = document.querySelector(SHOW_CONDITIONS_BUTTON_SELECTOR)

  if (!showConditionsButton) return

  function handleShowConditionsButtonClick() {
    if (currentDevice.isMobile) {
      initModal(`#${showConditionsButton.dataset.sidePanelId}`)
    } else {
      const modalId = `js-offer-modal-${showConditionsButton.dataset.offerId}`
      Modal.show(modalId, {
        onShow: modal => {
          ScrollLock.on()
          initModal(`#${modalId}`)

          // move texts from original element to modal
          const setTexts = modal.querySelectorAll('[data-take-text-from]:not([data-text-filled])')

          setTexts.forEach(textElem => {
            const originId = textElem.dataset.takeTextFrom

            if (!originId) return

            const originElem = document.querySelector(`#${originId}`)

            if (!originElem) return

            const clonedElement = originElem.cloneNode(true)

            clonedElement.className = ''

            textElem.appendChild(clonedElement)
            textElem.dataset.textFilled = ''
          })
        },
        onClose: () => {
          ScrollLock.off()
        },
        awaitCloseAnimation: true,
      })
    }
  }

  on({ eventName: 'click', selector: SHOW_CONDITIONS_BUTTON_SELECTOR, handler: handleShowConditionsButtonClick })
}

function initHeaderCustomizedWithOffer() {
  deactivateAllFilterBlocks()
  initShowHotelButtonClickLogic()
  initializeShowConditionsButton()
}

function updateOfferWithRealMarketContent(callback) {
  const blockSelector = '.js-header-with-offer'
  const mainContainer = document.querySelector(blockSelector)
  const blockId = mainContainer?.dataset.blockId
  const constrainedViewId = mainContainer?.dataset.constrainedViewId

  if (blockId && constrainedViewId) {
    getRealMarketContent({ blockId, constrainedViewId })
      .then(response => {
        const headerContent = document.querySelector('.js-header-with-offer-content')

        if (!headerContent) return

        headerContent.outerHTML = response.html_replacement

        dispatchRealMarketContentUpdatedEvent({ blockSelector, blockName: 'header-with-offer' })
        callback && callback()
      })
      .catch(error => {
        if (error !== 'alreadyApplied') return
        callback && callback()
      })
  } else {
    callback && callback()
  }
}

function initBlock() {
  // Init countdown (deadline requires jquery object)
  $('[data-deadline]')?.deadline()
  if (document.querySelector('.js-header-with-offer-cta')) {
    initHeaderCustomizedWithOffer()
  }

  Modal.init({
    openTrigger: 'data-modal-legal-conditions-trigger',
    awaitCloseAnimation: true,
  })

  initializeUtagEvents(BLOCK_SELECTOR)
}

function init() {
  const header = document.querySelector(BLOCK_SELECTOR)

  if (!header) return

  if (document.querySelector('.js-header-with-offer')) {
    updateOfferWithRealMarketContent(initBlock)
  } else {
    initBlock()
  }
}

init()
