import { createApp } from 'vue'

import DesktopMenu from '../src/mixins/DesktopMenu/DesktopMenu'
import LoadStyles from '../src/mixins/LoadStyles/LoadStyles'
import Menu from '../src/mixins/Menu/Menu'

import mainnav from '../../../../frontend/src/vue/src/components/MainNav/MainNav'
import privatearea from '../../../../frontend/src/vue/src/components/PrivateArea/PrivateArea'

const BASE_ID_SELECTOR = '#vue-main-nav'
const BASE_SELECTOR = '.for-main-nav .main-nav'
const LAST_SEARCHES_SELECTOR = '.last-searches-menu-element'
function init() {
  if (!document.querySelector(BASE_ID_SELECTOR)) return
  const mainNav = {
    mixins: [Menu, DesktopMenu],
    // Root components must be imported in lowercase
    components: {
      mainnav,
      privatearea,
    },
    data() {
      return {
        baseSelector: BASE_SELECTOR,
        lastSearchesSelector: LAST_SEARCHES_SELECTOR,
        currentItem: false,
        initializedBookingsForm: false,
        initializedLastSearches: false,
        imagesRequested: false,
        newDivFocus: null,
        privateAreaConfig: JSON.parse(document.querySelector(BASE_ID_SELECTOR).dataset.privateAreaConfig),
      }
    },
    created() {
      this.getData()
    },
    mounted() {
      this.initTemplates()
      this.pageSettings()
    },
    methods: {
      pageSettings() {
        !document.body.classList.contains('transparent-header') &&
          document.querySelector('.wrapper-main-header').classList.add('background-horizons')
        this.createElementDarkenBackground()
      },
      // Create dimmer element when links are hovered
      createElementDarkenBackground() {
        this.newDivFocus = document.createElement('div')
        this.newDivFocus.classList.add('vue-submenu-focus')
        document.body.appendChild(this.newDivFocus)
      },
      toggleDarkBackground(dark) {
        dark && this.newDivFocus.length
          ? this.newDivFocus.classList.add('active')
          : this.newDivFocus.classList.remove('active')
      },
      loadImages() {
        !this.imagesRequested && IB.lazyImg.loadAllImagesSelector($(this.baseSelector))
        this.imagesRequested = true
      },
      // Events Links
      selectedItem(itemName) {
        this.currentItem = itemName
        this.returnPanel(itemName)
        this.loadImages()
        this.toggleDarkBackground(true)
        switch (itemName) {
          case 'bookings':
            $(`${BASE_SELECTOR} .panel.bookings .hidden-img`).addClass('visible-img')
            $(`${BASE_SELECTOR} .panel.bookings .hidden-img`).removeClass('hidden-img')
            IB.lazyImg.loadAllImagesSelector($(`${this.baseSelector} .panel.bookings .visible-img`))
            if (!this.initializedBookingsForm) {
              IB.forms.reservation_management.init($(`${this.baseSelector} .js-reservation-management-form`))
              IB.formUtagEvents.init()
              this.initializedBookingsForm = true
            }
            break
          case 'searches':
            if (!this.initializedLastSearches) {
              $(`${BASE_SELECTOR} .panel.searches .hidden-img`).addClass('visible-img')
              $(`${BASE_SELECTOR} .panel.searches .hidden-img`).removeClass('hidden-img')
              IB.lazyImg.loadAllBackgroundImgSelector($(`${this.baseSelector} .panel.searches .visible-img`))
              this.initializedLastSearches = true
            }
            break
          case 'my-iberostar':
            $(`${BASE_SELECTOR} .panel.my-iberostar .hidden-img`).addClass('visible-img')
            $(`${BASE_SELECTOR} .panel.my-iberostar .hidden-img`).removeClass('hidden-img')
            if ($('.js-user-options-dropdown-link[data-menu-name="my-iberostar"]').length) {
              // If user is logged
              $('li.user-notifications').addClass('is-displayed')
              IB.lazyImg.loadAllBackgroundImgSelector($(`${this.baseSelector} .panel.my-iberostar .visible-img`))
            }
            break
          default:
        }
      },
      unSelectedItem() {
        this.currentItem = false
        this.closePanel()
        this.toggleDarkBackground(false)
      },
      // Events Panel
      isPanelOpen(itemName) {
        this.currentItem = itemName
        this.toggleDarkBackground(true)
      },
      isPanelClose() {
        this.currentItem = false
        this.closePanel()
      },
      closePanel() {
        // Set timeout to avoid closing the panel when moving from item to panel
        setTimeout(() => {
          if (this.currentItem !== false) {
            return false
          }
          this.returnPanel('hide')
          this.toggleDarkBackground(false)
        }, 200)
      },
    },
  }
  const app = createApp(mainNav)
  /**
   * TODO: Use Composables instead of Mixins
   * https://vuejs.org/api/options-composition.html#mixins
   * https://vuejs.org/guide/reusability/composables.html
   */
  app.mixin(LoadStyles)
  app.mount(BASE_ID_SELECTOR)
}

const MainNav = {
  init,
}

MainNav.init()

export default MainNav
