<template>
  <transition name="fade">
    <div
      v-if="activeNotification && show"
      :class="`popup-notification popup-notification--${popupPosition}`"
      :style="customStyles"
    >
      <button class="popup-notification__close-btn" @click="closePopup()">
        <span class="s s-close-fat"></span>
      </button>
      <div v-html="activeNotification.content"></div>
      <PopupLink
        v-if="
          activeNotification.parameters_to_link_this_page || activeNotification.url_link || activeNotification.url_cta
        "
        class="popup-notification__link"
        v-bind="promocodeInfo"
        :popup-event-act="(activeNotification.event_act || '').toLowerCase()"
        @close-popup="closePopup()"
        @popup-click="sendUtagEventFromComponent()"
      />
    </div>
  </transition>
</template>

<script>
import { isProxy, toRaw } from 'vue'

import { sendUtagEvent } from '../../../../core/analytics/utag_events'
import { getUserLevel } from '../../../../core/iberapi/browsing_session/browsing_session'
import PopupLink from '../PopupLink/PopupLink.vue'

export default {
  name: 'PopupNotification',
  components: {
    PopupLink,
  },
  props: {
    notifications: {
      required: true,
    },
    applyCodeLang: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
      activeNotification: false,
    }
  },
  computed: {
    customStyles() {
      return {
        '--bg-color': this.activeNotification.background_color ? this.activeNotification.background_color : '#032147',
        '--font-size': this.activeNotification.font_size ? `${this.activeNotification.font_size}px` : '1rem',
        '--font-size-sm': this.activeNotification.font_size_mobile
          ? `${this.activeNotification.font_size_mobile}px`
          : '1rem',
        '--font-color': this.activeNotification.font_color ? this.activeNotification.font_color : 'var(--c_white)',
        '--link-color': this.activeNotification.color_link ? this.activeNotification.color_link : '#67BAAF',
      }
    },
    popupPosition() {
      return this.activeNotification.pop_up_position ? this.activeNotification.pop_up_position : 'left'
    },
    promocodeInfo() {
      return {
        url_link: this.activeNotification.url_link,
        text_link: this.activeNotification.text_link,
        url_cta: this.activeNotification.url_cta,
        promocode: this.activeNotification.promocode,
        parameters: this.activeNotification.parameters_to_link_this_page,
        apply_code_lang: this.applyCodeLang,
        popup_event_act: (this.activeNotification.event_act || '').toLowerCase(),
      }
    },
  },
  mounted() {
    this.activeNotification = this.filterActiveNotification(JSON.parse(this.notifications))

    if (this.activeNotification) {
      const userLevel = getUserLevel()

      // Show notification only if user level matches notification allowed logged_in_levels
      if (!this.activeNotification.logged_in_levels) return

      let allowedUserLevels = this.activeNotification.logged_in_levels

      if (isProxy(allowedUserLevels)) {
        allowedUserLevels = toRaw(allowedUserLevels)
      }

      const isVisibleToUser = allowedUserLevels.includes(userLevel)

      if (isVisibleToUser) {
        setTimeout(() => {
          this.show = true
        }, 1000)
      }
    }
  },
  methods: {
    filterActiveNotification(notifications) {
      const now = new Date()

      return notifications?.find(notification => {
        const startDate = new Date(notification.single_date_constraint_starts_at)
        const endDate = notification.single_date_constraint_ends_at
          ? new Date(notification.single_date_constraint_ends_at)
          : false

        if (now >= startDate && (now <= endDate || !endDate)) {
          return notification
        }
      })
    },
    sendUtagEventFromComponent() {
      const event_cat = this.activeNotification.event_cat || 'emergent'
      const event_act = (this.activeNotification.event_act || '').toLowerCase()
      const event_lbl = this.activeNotification.event_lbl

      sendUtagEvent({
        data: {
          event_name: 'emergent_module',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'information',
          event_structure: 'popup',
        },
      })
    },

    closePopup() {
      this.show = false
    },
  },
}
</script>
