<template>
  <li
    v-if="!user.logged_in || user.logged_in !== 'true'"
    class="menu_item has-children not-hide-on-tablet without-notifications js-myibe-hide"
    data-menu-name="my-iberostar"
    @click.prevent="showLoginSSO"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <a
      id="login_auth0"
      href="#"
      class="user-options-link-wrapper js-user-options-link utag-ib-register guest"
      data-utag-ib-register-type="header"
      :data-user-url="`${dataUser.userGuest.userUrl}`"
    >
      <div class="icon"></div>
      <span class="name">{{ dataUser.userGuest.text }}</span>
    </a>
  </li>
  <li
    v-else
    class="dropdown-link has-children js-user-options-dropdown-link without-notifications js-myibe-hide"
    data-menu-name="my-iberostar"
    :class="`${isCurrentItem ? 'is-displayed' : ''}`"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <a href="#" class="logout user-options-link-wrapper js-user-options-link">
      <div :class="`user-image js-img ${userImageClass}`">
        <img v-if="user.image_url && user.image_url != ''" :src="user.image_url" alt="Avatar" class="image" />
        <span v-else-if="user.name && user.name != ''" class="user-image-letter">
          {{ user.name.charAt(0) }}
        </span>
      </div>

      <span class="name">{{ user.name }}</span>
      <span
        v-if="user.sta_points != null"
        class="sta-points"
        data-points-es="Puntos"
        data-points-en="Points"
        data-points-de="Punkte"
        data-points-fr="Points"
        data-points-pt="Pontos"
        >({{ user.sta_points }} pkey)
      </span>
    </a>
  </li>
</template>

<script>
import { auth0Login } from '../../../../auth0/sso'
import loaderSpinner from '../../../../shared/loader_spinner/loader_spinner'

const componentName = 'MainNavUser'
export default {
  name: componentName,

  components: {},
  props: {
    config: Object,
    item: Object,
    dataUser: Object,
    currentItem: String,
    utagEventInfo: Object,
  },
  data() {
    return {
      user: { logged_in: false },
      isSelected: false,
    }
  },
  computed: {
    isCurrentItem() {
      return this.currentItem === this.item.badge
    },
    /**
     * TODO: refactor classes to be inside Vue component
     */
    userImageClass() {
      let klass = 'user-image-with-'
      if (this.user.image_url && this.user.image_url !== '') {
        klass += 'avatar'
      } else {
        klass += 'letter user-image-flex with-name'
      }
      return klass
    },
  },
  mounted() {
    IB.browsing_sessions.readRedis(this.init)

    if ($.cookie('agency_user_web_name')) {
      this.user.name = $.cookie('agency_user_web_name')
      this.user.logged_in = $.cookie('agency_user_web_name') ? 'true' : 'false'
    }
  },
  updated() {
    if ($('.sta-points').length > 0) {
      const points_text = $('.sta-points').data(`points-${document.documentElement.getAttribute('data-js-lang')}`)
      if ($('.js-current-agent-points').length > 0) {
        $('.sta-points').text(`(${$('.js-current-agent-points').text()} ${points_text})`)
      } else {
        $('.sta-points').text($('.sta-points').text().replace('pkey', points_text))
      }
    }
  },
  methods: {
    showLoginSSO() {
      loaderSpinner.show()
      auth0Login(false)
    },
    init() {
      if (IB.browsing_sessions !== null && IB.browsing_sessions.getData()) {
        this.user = IB.browsing_sessions.getData().redis_values
      }
    },
    onMouseEnter(event) {
      this.isSelected = true
      this.$emit('selected', this.user.logged_in ? this.item.badge : 'horizons')
    },
    onMouseLeave(event) {
      this.isSelected = false
      this.$emit('unSelected', this.user.logged_in ? this.item.badge : 'horizons')
    },
  },
}
</script>
