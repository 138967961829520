const getOffset = selector => {
  let rect
  if (selector instanceof String) {
    rect = document.querySelector(selector).getBoundingClientRect()
  } else {
    rect = selector.getBoundingClientRect()
  }
  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  }
}

export default getOffset
