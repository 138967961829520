import { getPrefix } from '../../core/utils/locale'
import { checkloadComponentStyles } from '../../lazy_wrappers/lazy_block_lightbox'
import { readCookie } from '../../utils/cookie_manager'
import on from '../../utils/events_delegation'
import { getLazyData } from '../../utils/get_lazy_data'

function handlerShowDiscounts(e) {
  e.preventDefault()
  let resident = readCookie('resident')
  const showLegal = this.dataset.showLegal
  const offerStart = this.dataset.offerStart
  const offerEnd = this.dataset.offerEnd
  const showGeneralDatesOffer = this.dataset.showGeneralDatesOffer
  const offerEndVisualizationDate = this.dataset.offerEndVisualizationDate
  const showGeneralDatesDestination = this.dataset.showGeneralDatesDestination
  resident = resident === undefined ? '' : resident
  const url = `${getPrefix()}/ajax_contents/lightbox_discounts.js?resident=${resident}&showLegal=${showLegal}&offerStart=${offerStart}&offerEnd=${offerEnd}&showGeneralDatesOffer=${showGeneralDatesOffer}&offerEndVisualizationDate=${offerEndVisualizationDate}showGeneralDatesDestination=${showGeneralDatesDestination}`
  getLazyData(url, function (lightboxHtml) {
    $.fancybox.open(lightboxHtml)
    checkloadComponentStyles()
  })
}

export function init() {
  on({ eventName: 'click', selector: '.js-discount-info', handler: handlerShowDiscounts })
}

init()
