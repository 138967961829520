<template>
  <div
    class="main-nav-panel bookings"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot></slot>
  </div>
</template>

<script>
// PY04776-590
// Control the panel when the calendar is open
import { DATA_RANGE_PICKER_OPENED, DATA_RANGE_PICKER_CLOSED } from '../../../../shared/forms/reservation_management_form'

const componentName = 'MainNavPanelBooking';

export default {
  name: componentName,
  props: {
    config: Object,
  },
  data() {
    return {
      isUserLogged: false,
      item: "bookings",
      calendarIsOpen: false,
    }
  },
  mounted() {
    IB.browsing_sessions.readRedis(this.init);

    if ($.cookie('agency_user_web_name')) {
      this.user.name = $.cookie('agency_user_web_name');
      this.user.logged_in = !!$.cookie('agency_user_web_name') ? 'true': 'false';
    }
    this.isCalendarOpen()
  },
  methods: {
    init() {
      if (IB.browsing_sessions !== null && IB.browsing_sessions.getData()) {
        this.user = IB.browsing_sessions.getData().redis_values;
      }
    },
    onMouseEnter(event) {
      this.$emit('onPanel', this.item);
    },
    onMouseLeave(event) {
      if(this.calendarIsOpen === false) {
        this.$emit('outPanel', this.item);
      }
    },
    isCalendarOpen() {
      document.addEventListener(DATA_RANGE_PICKER_OPENED, ()=> {
        this.calendarIsOpen = true
      });

      document.addEventListener(DATA_RANGE_PICKER_CLOSED, ()=> {
        this.calendarIsOpen = false
      })
    }
  },
}
</script>
