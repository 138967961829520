import { createApp } from 'vue'

import LoadStyles from '../src/mixins/LoadStyles/LoadStyles'

import popupnotification from '../src/components/PopupNotification/PopupNotification'

const BASE_ID_SELECTOR = '#vue-popup-notification'

function init() {
  if (!document.querySelector(BASE_ID_SELECTOR)) return

  const app = createApp({
    el: BASE_ID_SELECTOR,
    // Root components must be imported in lowercase
    components: {
      popupnotification,
    },
  })

  /**
   * TODO: Use Composables instead of Mixins
   * https://vuejs.org/api/options-composition.html#mixins
   * https://vuejs.org/guide/reusability/composables.html
   */
  app.mixin(LoadStyles)
  app.mount(BASE_ID_SELECTOR)
}

init()
