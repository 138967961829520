<template>
  <div
    class="main-nav-panel continents"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'MainNavPanelHotelsDestinations';

export default {
  name: componentName,

  data() {
    return {
      item: "continents",
    }
  },

  methods: {
    onMouseEnter(event) {
      this.$emit('onPanel', this.item);
    },
    onMouseLeave(event) {
      this.$emit('outPanel', this.item);
    }
  }
}
</script>
