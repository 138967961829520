function init() {
  const $block = $('.landing-typekit-header')
  if (!$block.length) return

  const $image = $block.find('.image')
  const $hotel_list = $("div[data-block-layout='hotels_info_with_filtering'], div[data-block-layout='hotel_list']")

  if ($hotel_list.length) $block.find('a[href="#hotel-list"]').removeClass('not-visible')
  if ($image.length && IB.currentDevice !== 'desktop' && $image.data('mobile') !== '' && $image.data('mobile') !== undefined) {
    $image.css('background-image', `url(${$image.data('mobile')})`)
  }

  const $menu_items = $block.find('.internal-nav-cnt-nfh')
  if ($menu_items.length) {
    IB.currentDevice === 'mobile' ? initMenuItemsMobile($menu_items) : initMenuItems($block, $menu_items)

    initCheckInternalNav()

    if (IB.currentDevice === 'tablet') {
      let position = $(window).scrollTop()
      const $header_sticky = $('#header-sticky')
      const distance = $menu_items.offset().top + $menu_items.height()
      $(window).on('scroll', function () {
        const scroll = $(window).scrollTop()
        if (scroll > distance) {
          if (scroll > position) $header_sticky.css('transform', 'translate(0, 300px)')
          else $header_sticky.css('transform', 'translate(0, 378px)')
        } else $header_sticky.css('transform', 'translate(0, -90px)')

        position = scroll
      })
    }
  }

  handlerFastbookingScroll($block)
}

function handlerFastbookingScroll($block) {
  const fastbooking = document.querySelector('.fastbooking.home')
  const cta = Array.from($block.get(0).querySelectorAll('.js-book-btn'))

  if (fastbooking && Array.isArray(cta) && cta.length > 0) {
    fastbooking.classList.add('sticky')
    window.addEventListener('scroll', closeFastbookingWhenUserScrollsMoreThan100pxInLessThanOneSecond)
  }
}

let lastScrollPosition = 0
let lastScrollTime = 0

function closeFastbookingWhenUserScrollsMoreThan100pxInLessThanOneSecond() {
  const currentPosition = window.pageYOffset
  const distance = Math.abs(currentPosition - lastScrollPosition)
  const time = performance.now() - lastScrollTime

  if (distance > 100 && time < 1000) {
    window.IB.fastbooking.sticky.close()
  }

  lastScrollPosition = currentPosition
  lastScrollTime = performance.now()
}

function initMenuItems($block, $menu) {
  const $clone_menu = $menu.first().clone(true, true)
  const $header_sticky = $('#header-sticky')
  $menu.find('.internal-nav').addClass('no-sticky')

  $header_sticky.find('.sticky-internal-nav .wrapper').html($clone_menu)

  const block_height = $block.outerHeight()
  const block_bottom = $block.offset().top + block_height

  $(window).on('scroll', function () {
    menuSticky(block_bottom, $header_sticky)
  })
}

function initMenuItemsMobile($menu) {
  const $mobile_sticky_container = $('.footer-fixed-buttons')
  if ($mobile_sticky_container.length) $mobile_sticky_container.prepend($menu)
}

function menuSticky(block_bottom, $header_sticky) {
  if (window.pageYOffset > block_bottom + 20) {
    $header_sticky.addClass('active')
  } else {
    $header_sticky.removeClass('active')
    $('#more-container-sticky').hide()

    $('.floating-action .active').map(function (index, elem) {
      return $(elem).removeClass('active')
    })
  }
}

let blocksObserver
let initialized = false

function initCheckInternalNav() {
  if (initialized) return
  initialized = true

  const arr_menu = []
  const $sticky_header = $('#header-sticky')
  const header_height = $sticky_header.height()

  $('.internal-nav-cnt-nfh')
    .first()
    .find('ul li.menu_item:not(.multi-button) a')
    .each(function () {
      arr_menu.push($(this).attr('href'))
    })

  // Si se deja de dar soporte a IE11, podríamos borrar esta comprobación y el método checkMenuLink entero y dejar solo el código del else.
  if (!('IntersectionObserver' in window)) {
    $(window).on('scroll', function () {
      checkMenuLink($(this))
    })
  } // end IE11
  else {
    /** -------- INTERSECTION OBSERVER ACTIVE INTERNAL LINK -------- */
    const options_blocks = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.5,
    }

    const $blocks = $('[role="main"] > *[name]')

    blocksObserver = new IntersectionObserver(activeBlock, options_blocks)
    $blocks.each(function (i, block) {
      blocksObserver.observe(block)
    })
  }

  function checkMenuLink($el) {
    const $items = $('.internal-nav-cnt-nfh ul li.menu_item:not(.multi-button)')
    const $this = $el
    const scroll_top = $sticky_header.hasClass('active') ? $this.scrollTop() + header_height : $this.scrollTop()

    $items.removeClass('active')

    if (!$sticky_header.hasClass('active') && IB.currentDevice !== 'mobile') {
      $items.find(`[href="${arr_menu[0]}"]`).parent('.menu_item').addClass('active')
      return false
    }

    $.each(arr_menu, function (indexInArray, value) {
      const $box = $(value).length ? $(value) : $(`[name=${value.slice(1)}]`)

      if (!$box.length) return false

      const is_mobile = IB.currentDevice === 'mobile'
      let scroll_compensation = is_mobile ? $('.internal-nav-cnt-nfh:visible .menu-trigger-responsive').outerHeight() + 21 : 30

      if (is_mobile && $box.offset().top < scroll_top) {
        // will scroll up, so header will be visible
        scroll_compensation += $('.mobile-header-cnt').height() + 10
      }

      const box_top = $box.offset().top - scroll_compensation
      const box_bottom = box_top + $box.height()

      if (alreadyInView(scroll_top, box_top, box_bottom)) {
        const $item = $items.find(`[href="${value}"]`).parent('.menu_item')
        $item.addClass('active')
      }
    })
  }
}

function activeBlock(entries) {
  $.each(entries, function (i, entry) {
    if (entry.isIntersecting) {
      const $block = $(entry.target)
      const value = $block.attr('name')
      const $items = $('.internal-nav-cnt-nfh ul li.menu_item:not(.multi-button)')
      $items.removeClass('active')
      if (value !== undefined && value !== null) {
        const $item = $items.find(`[href="#${value}"]`).parent('.menu_item')
        $item.addClass('active')
      }
    }
  })
}

function alreadyInView(elemTop, docViewTop, docViewBottom) {
  return elemTop >= docViewTop && elemTop <= docViewBottom
}

window.IB.landing_typekit_header = {
  init,
}

IB.landing_typekit_header.init()
