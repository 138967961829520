import { loadHlsVideo } from '../../utils/load_hls_video'

init()
function init() {
  loadHlsVideo('.video-looping')
}

$(function () {
  const $video = $('#head-video')

  if ($video.length === 0) return false

  const mp4_src = $video.data('mp4-src')
  const webm_src = $video.data('webm-src')

  $video.append(`<source src="${mp4_src}" type="video/mp4"><source src="${webm_src}" type="video/webm">`)
})
