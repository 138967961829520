import { setPromocodeInFastbooking } from '../promo_code/promo_code';

/**
 * The referenced tag should contain:
 * data-url-link with the url link
 * data-text-link with the text to show in the link
 * data-promocode with the promocode
 * data-url-cta with the url to redirect that contains the promocode
 * data-apply-code-lang with the translation to apply the promocode
 * Example: <span class="js-promolink" data-url-link="" data-text-link="" data-promocode="10RABATT" data-url-cta="" data-apply-code-lang="Aplicar código"></span>
 *
 * @param {*} promolink - DOM element with the datas
 * @returns void
 */
export default function initPromoLink(promolink) {
  const urlLink = promolink.dataset.urlLink;
  const textLink = promolink.dataset.textLink;
  const promocode = promolink.dataset.promocode;
  const urlCta = promolink.dataset.urlCta;
  const parameters = promolink.dataset.parameters;
  const applyCodeLang = promolink.dataset.applyCodeLang;

  if (urlLink === '' && promocode === '') {
    promolink.remove();
    return;
  }

  let href = '#';
  if (parameters) {
    href = location.protocol + '//' + location.host + location.pathname + '?' + parameters;
  } else if ((urlLink && promocode) || urlLink !== '') {
    href = urlCta;
  } else if (urlLink === '' && promocode && urlCta !== '') {
    href = window.location.origin + urlCta;
  }

  let innerText = `${applyCodeLang} ${promocode}`;
  if (textLink) {
    innerText = textLink;
  }

  function clickHandler(e) {
    if (!parameters && promocode && !urlLink && !urlCta) {
      setPromocodeInFastbooking(promocode)
      e.preventDefault();
    }
  }

  promolink.innerHTML = `<a href="${href}" class="promo-link" >${innerText} <span class="s s-arrow-down-short-tail"></span></a>`;
  promolink.addEventListener('click', clickHandler);
}
