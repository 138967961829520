<template>
  <li
    v-if="item.name || item.item?.name"
    :class="`menu_item ${item.badge === 'offers' ? 'utag-header-offers' : ''} ${
      item.badge === 'searches' ? 'last-searches-menu-element' : ''
    } ${hideItem} ${isCurrentItem && isFixedUrl ? 'is-displayed' : ''} ${isFixedUrl}`"
    :data-menu-name="item.name ? item.name : item.badge"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <a
      :href="itemUrl"
      :target="item.target || null"
      :rel="item.rel ? item.rel : false"
      :data-no-turbolink="item.turbolinks ? false : true"
      :data-visible-to-the-club-user="item.visible_to_the_club_user ? item.visible_to_the_club_user : false"
      :data-event-label="item.kind == 'page' ? item.event_name : false"
      :data-method="item.logout_method ? item.logout_method : false"
      :data-menu-name="item.name ? item.name : item.badge"
      @click="handleClick"
    >
      {{ item.name ? item.name : item.item.name }}
    </a>
  </li>
</template>

<script>
import MenuItem from '../../mixins/MenuItem/MenuItem'

import { emitMainNavItemEvent } from './MainNavItem.analytics'

const componentName = 'MainNavItem'
export default {
  name: componentName,

  mixins: [MenuItem],

  props: {
    item: Object,
    utagEventInfo: Object,
    currentItem: String,
  },

  data() {
    return {
      isSelected: false,
    }
  },

  computed: {
    menuId() {
      if (this.item.badge) {
        return this.item.badge
      }
      return `item-${this.item.id}`
    },
    isCurrentItem() {
      if (!this.menuId) return false
      return this.currentItem === this.menuId
    },
    /**
     * Hide menu item if some coditions apply
     */
    hideItem() {
      // Hide last searches if not last searches found
      if (this.menuId === 'searches' && !window.localStorage.getItem('last_searches')) {
        return 'hidden'
      }
      return ''
    },
    isFixedUrl() {
      if ((this.item.kind && this.item.kind === 'fixed_url') || this.item.kind === 'page') return ''
      return 'dropdown-link has-children'
    },
    itemUrl() {
      if (this.item.name === 'Recursos' || this.item.name === 'Mi Cuenta') {
        return false
      }
      return this.item?.url ? this.item?.url : this.item?.item?.url ? this.item?.item?.url : ''
    },
  },

  methods: {
    onMouseEnter(event) {
      this.isSelected = true
      this.$emit('selected', this.menuId)
    },
    onMouseLeave(event) {
      this.isSelected = false
      this.$emit('unSelected', this.menuId)
    },
    handleClick() {
      emitMainNavItemEvent(this.utagEventInfo)
    },
  },
}
</script>
