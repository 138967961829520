<template>
  <div class="main-nav-panel" :class="[itemId]" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="panel" :class="{ active: isActive }">
      <div class="panel-content">
        <nav>
          <ul class="panel-content__subitems">
            <li v-for="(subitem, index) in item.subitems" :key="index" class="panel-content__subitems__list">
              <a :class="subitem.css_class" :href="subitem.url.path ? subitem.url.path : subitem.url">{{
                subitem.name
              }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { default as initSTAModal } from '../../../../microsites/staragents/shared/sta_modal_form/sta_modal_form'

const componentName = 'MainNavPanel'

export default {
  name: componentName,

  props: {
    item: Object,
    currentItem: String,
  },

  data() {
    return {
      itemId: `item-${this.item.id}`,
    }
  },

  computed: {
    isActive() {
      return this.itemId === this.currentItem
    },
  },

  mounted() {
    const microsite = document.documentElement.dataset.inMicrosite
    if (microsite === 'star_agents') {
      initSTAModal()
    }
  },

  methods: {
    onMouseEnter(event) {
      this.$emit('onPanel', this.itemId)
    },
    onMouseLeave(event) {
      this.$emit('outPanel', this.itemId)
    },
  },
}
</script>
